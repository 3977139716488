@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800&display=swap');

body {
    margin: 0;
    text-align: left;
    background-color: #fff;
    overflow-x: hidden;
}

a {
    cursor: pointer;
}
a:hover,
a:focus {
    text-decoration: none;
}

/*
Overrides "overlayscrollbars" package default styles.
"important" used because of bug in webpack causes the app.css being always resolved before the package styles.
More info: https://github.com/webpack/webpack/issues/215.
*/
.os-theme-dark > .os-scrollbar-vertical,
.os-theme-light > .os-scrollbar-vertical {
    width: 12px !important;
    bottom: 0 !important;
}
.os-theme-dark > .os-scrollbar-horizontal,
.os-theme-light > .os-scrollbar-horizontal {
    height: 12px !important;
    right: 12px !important;
}
.os-theme-dark.os-host-rtl > .os-scrollbar-horizontal,
.os-theme-light.os-host-rtl > .os-scrollbar-horizontal {
    left: 12px !important;
}
