.wrapper {
  margin-top: 10px;
}

.actions {
  width: 21cm;
  margin: 0 auto;
}

.actions a {
  align-self: center;
  font-weight: 600;
  font-size: 16px;
  margin-right: 1rem;
}

.inv .clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.inv a {
  color: #5D6975;
  text-decoration: underline;
}

.inv {
  position: relative;
  width: 21cm;
  height: 29.7cm; 
  margin: 0 auto;
  color: #001028;
  background: #FFFFFF; 
  font-family: Arial, sans-serif; 
  font-size: 12px; 
  font-family: Arial;
}

.inv header {
  padding: 10px 0;
  margin-bottom: 30px;
}

.inv #logo {
  text-align: center;
  margin-bottom: 10px;
}

.inv #logo img {
  width: 90px;
}

.inv h1 {
  border-top: 1px solid  #5D6975;
  border-bottom: 1px solid  #5D6975;
  color: #5D6975;
  font-size: 2.4em;
  line-height: 1.4em;
  font-weight: normal;
  text-align: center;
  margin: 0 0 20px 0;
}

.inv #project {
  float: left;
}

.inv #project span {
  color: #5D6975;
  text-align: right;
  width: 52px;
  margin-right: 10px;
  display: inline-block;
  font-size: 0.8em;
}

.inv #company {
  float: right;
  text-align: right;
}

.inv #project div,
.inv #company div {
  white-space: nowrap;        
}

.inv table {
  font-size: 16px;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
}

.inv table tr:nth-child(2n-1) td {
  background: #F5F5F5;
}

.inv table th,
.inv table td {
  text-align: center;
}

.inv table th {
  padding: 5px 20px;
  color: #5D6975;
  border-bottom: 1px solid #C1CED9;
  white-space: nowrap;        
  font-weight: normal;
}

.inv table .service,
.inv table .desc {
  text-align: left;
}

.inv table td {
  padding: 20px;
  text-align: right;
}

.inv table td.service,
.inv table td.desc {
  vertical-align: top;
}

.inv table td.unit,
.inv table td.qty,
.inv table td.total {
  font-size: 1.2em;
}

.inv table td.grand {
  border-top: 1px solid #5D6975;;
}

.inv #notices .notice {
  color: #5D6975;
  font-size: 1.2em;
}

.inv footer {
  color: #5D6975;
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #C1CED9;
  padding: 8px 0;
  text-align: center;
}
